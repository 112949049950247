body {
  font-family: 'Poppins', sans-serif;
  background: #fafafa;
}

p {
  font-family: 'Poppins', sans-serif;
  font-size: 1.1em;
  font-weight: 300;
  line-height: 1.7em;
  color: #000;
}

a, a:hover, a:focus {
  color: inherit;
  text-decoration: none;
  transition: all 0.3s;
}

#sidebar {
  /* don't forget to add all the previously mentioned styles here too */
  background: #ffff;
  color: #000;
  transition: all 0.3s;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}

  #sidebar .sidebar-header {
      padding: 18px;
      background: #6d7fcc;
  }

  #sidebar ul.components {
      padding: 10px;
      border-bottom: 1px solid #47748b;
  }

  #sidebar ul p {
      color: #000;
      padding: 8px;
  }

  #sidebar ul li a {
      padding: 8px;
      font-size: 1.1em;
      display: block;
  }

      #sidebar ul li a:hover {
          color: #009fe3;
          background: #fff;
      }

  #sidebar ul li.active > a, a[aria-expanded="true"] {
      color: #fff;
      background: #009fe3;
  }

ul ul a {
  font-size: 0.9em !important;
  padding-left: 15px !important;
  background: #009fe3;
}

@media (max-width: 768px) {
  #sidebar {
      text-align: center;
      float: none;
      width:50px;
  }
      #sidebar ul.components {
          padding: 1px;
          border-bottom: 1px solid #47748b;
      }


      #sidebar.active {
          margin-left: 0;
      }
      #sidebar ul p {
          color: #000;
          padding: 1px;
      }
}

/*@media screen and (device-width: 320px) and (device-height: 568px) and (-webkit-device-pixel-ratio: 2) {
  #sidebar {
      /*margin-left: -100px;*/
      /*flex-shrink:inherit;
      text-align: center;
      width: 50%;
      padding: 1px;
      min-height: 150vh;
  }

      #sidebar ul.components {
          padding: 1px;
          border-bottom: 1px solid #47748b;
      }

      #sidebar.active {
          margin-left: 0;
      }

      #sidebar ul p {
          color: #999;
          padding: 1px;
      }
}*/

/*a[data-toggle="collapse"] {
  position: relative;
}*/

/*.dropdown-toggle::after {
  display: block;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}*/

.wrapper {
  display: flex;
  align-items: stretch;

}


#sidebar {
  min-width: 300px;
  max-width: 300px;
  min-height: 100vh;
  /*max-height: 100vh;*/
  /*position:fixed;*/
}
  #sidebar.inactive {
      margin-left: -250px;
  }
  #sidebar.active {
      margin-left: 0px;

  }
